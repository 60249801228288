<template>
    <div>
        <div class="mb-3 demo-spin-article">
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class=" tw-flex tw-ml-12">
                    <div class="tw-space-x-4">
                        <label class="col-label form-label tw-text-xs">{{
                            $t("monthlyCompensate.year")
                        }}</label>
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width:320px"
                            @on-change="cycleYearChange"
                        >
                        </date-Picker>
                    </div>
                    <div class=" tw-space-x-4">
                        <label
                            class="col-label form-label tw-text-xs tw-ml-12"
                            >{{ $t("monthlyCompensate.month") }}</label
                        >
                        <DatePicker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width:320px"
                            format="MM"
                            @on-change="cycleMonthChange"
                        >
                        </DatePicker>
                    </div>
                </div>
                <button
                    type="button"
                    class="btn ts-whitespace-no-wrap btn-default btn-primary mr-2"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onGenerate"
                >
                    Generate
                </button>
            </ts-panel-wrapper>
            <div class="mb-3">
                <Input
                    v-model="search"
                    search
                    :placeholder="$t('monthlyCompensate.searchEmployeeName')"
                />
            </div>
            <Table
                highlight-row
                ref="selection"
                :columns="columns"
                :data="employees"
                max-height="500"
                size="small"
                @on-select="setEmployeeIdListSelected" 
                @on-select-cancel="setEmployeeIdListSelected"
                @on-select-all="setEmployeeIdListSelected"
                @on-select-all-cancel="setEmployeeIdListSelected"
                stripe
            >
                <template slot-scope="{ row }" slot="branch_name">
                    {{
                        locale == "kh" ? row.branch_name_kh : row.branch_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="employee_name">
                    {{
                        locale == "kh"
                            ? row.enployee_name_kh
                            : row.employee_name_en
                    }}
                </template>
            </Table>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
    name: "monthlyCompensateSalaryBackPay",
    props: ["compensate"],
    data() {
        return {
            selectedLeaveRecords: [],
            resources: [],
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            search: "",
            model: {
                custom_keyword: null,
                compensate_id: null,
                cycle_year: moment().format("YYYY"),
                cycle_month: moment().format("MM"),
                object_id_list: []
            }
        };
    },
    computed: {
        employees() {
            if (this.resources.length > 0 && this.search) {
                return this.resources.filter(
                    p =>
                        p.card_id
                            .indexOf(this.search.toLowerCase()) > -1 ||
                        p.employee_name_en
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1 ||
                        p.employee_name_kh
                            .indexOf(this.search.toLowerCase()) > -1 ||
                        p.branch_name_en
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1 ||
                        p.branch_name_kh
                            .indexOf(this.search.toLowerCase()) > -1
                );
            }
            return this.resources;
        },
        columns() {
            return [
                {
                    type: "selection",
                    width: 60,
                    align: "center"
                },
                {
                    title: "Branch Name",
                    slot: "branch_name",
                    align: "center",
                    sortable: true
                },
                {
                    title: "ID",
                    key: "card_id",
                    align: "center",
                    sortable: true
                },
                {
                    title: "Employee Name",
                    slot: "employee_name",
                    sortable: true
                },
                {
                    title: "Hire Date",
                    key: "hire_date",
                    align: "center"
                },
                {
                    title: "Total Day",
                    key: "anl_day_remain",
                    align: "center"
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("payroll/monthlyCompensate", ["getEmployeeList"]),
        fetchResource() {
            this.loading = true;
            this.getEmployeeList({
                cycle_year: this.model.cycle_year,
                cycle_month: this.model.cycle_month,
                custom_keyword: this.model.custom_keyword
            })
                .then(res => {
                    this.resources = res.data;
                    this.$nextTick(() => this.$refs.selection.selectAll(true));
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onGenerate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "payroll/monthlyCompensate/importSalaryBackPay",
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        cycleYearChange(value) {
            this.loading = true;
            this.model.cycle_year = value;
            this.getEmployeeList({
                cycle_year: value,
                cycle_month: this.model.cycle_month,
                custom_keyword: this.model.custom_keyword
            })
                .then(res => {
                    this.resources = res.data;
                    this.$nextTick(() => this.$refs.selection.selectAll(true));
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cycleMonthChange(value) {
            this.loading = true;
            this.model.cycle_month = value;
            this.getEmployeeList({
                cycle_year: this.model.cycle_year,
                cycle_month: value,
                custom_keyword: this.model.custom_keyword
            })
                .then(res => {
                    this.resources = res.data;
                    this.$nextTick(() => this.$refs.selection.selectAll(true));
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setEmployeeIdListSelected(records) {
            this.model.object_id_list = [];
            if (records.length > 0) {
                records.map(row => {
                    this.model.object_id_list.push(row.employee_id);
                });
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY COMPENSATE",
                desc: not.text
            });
        }
    }
};
</script>
