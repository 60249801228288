var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('monthlyCompensate.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('monthlyCompensate.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-space-x-2"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]),_c('div',{staticClass:"btn-group"},[_c('Dropdown',{staticClass:"btn btn-default",attrs:{"trigger":"click"}},[_c('a',[_vm._v(" Import "),_c('Icon',{attrs:{"type":"ios-arrow-down"}})],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},_vm._l((_vm.itemCompensate),function(compensate,index){return _c('DropdownItem',{key:index},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.onImportCompensate(compensate)}}},[_vm._v(" "+_vm._s(compensate.compensate_item)+" ")])])}),1)],1)],1)],1),_c('div',{staticClass:"tw-flex tw-space-x-2"},[_c('div',[_c('DatePicker',{attrs:{"type":"year","format":"yyyy","placeholder":"Select Year","value":_vm.year},on:{"on-change":_vm.onChangeYear}})],1),_c('div',[_c('DatePicker',{attrs:{"type":"month","format":"MM","placeholder":"Select Month","value":_vm.month},on:{"on-change":_vm.onChangeMonth}})],1),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('monthlyCompensate.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('Table',{attrs:{"columns":_vm.columns,"size":"small","max-height":"700","loading":_vm.loading,"data":_vm.resources,"stripe":""},scopedSlots:_vm._u([{key:"employeeId",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$root.$i18n.locale === 'kh' ? row.employee_name_kh : row.employee_name_en)+" ")]}},{key:"compensateId",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.compensate_item)+" ")]}},{key:"compensate",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-success"},[_vm._v(_vm._s(_vm.formatNumber(row.compensate)))])]}},{key:"description",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.description)+" ")]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no'),"placement":"left","transfer":true},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":function($event){return _vm.fetchData({
                        filter: {
                            search: _vm.search,
                            cycle_year: _vm.year,
                            cycle_month: _vm.month
                        }
                    })}},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyCompensate.importSalaryBackPay')},model:{value:(_vm.showSalaryBackPay),callback:function ($$v) {_vm.showSalaryBackPay=$$v},expression:"showSalaryBackPay"}},[_c('salary-back-pay',{ref:"salary_back_pay"})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyCompensate.importAnnualLeaveRemaining')},model:{value:(_vm.showAnnualLeaveRemaining),callback:function ($$v) {_vm.showAnnualLeaveRemaining=$$v},expression:"showAnnualLeaveRemaining"}},[_c('annual-leave-remaining',{ref:"annual_leave_remaining"})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1052,"width":"1200px","title":_vm.$t('monthlyCompensate.importAllowanceBackPay')},model:{value:(_vm.showAllowanceBackPay),callback:function ($$v) {_vm.showAllowanceBackPay=$$v},expression:"showAllowanceBackPay"}},[_c('allowance-back-pay',{ref:"allowance_back_pay"})],1)],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('monthlyCompensate.monthlyCompensate')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('FormComponent',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":function($event){return _vm.fetchData({
                    filter: {
                        search: _vm.search,
                        cycle_year: _vm.year,
                        cycle_month: _vm.month
                    }
                })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }