<template>
    <div>
        <ts-page-title
            :title="$t('monthlyCompensate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monthlyCompensate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="btn-group">
                        <Dropdown trigger="click" class="btn btn-default">
                            <a>
                                Import
                                <Icon type="ios-arrow-down"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem
                                    v-for="(
                                        compensate, index
                                    ) in itemCompensate"
                                    :key="index"
                                >
                                    <a
                                        @click.prevent="
                                            onImportCompensate(compensate)
                                        "
                                    >
                                        {{ compensate.compensate_item }}
                                    </a>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div class="tw-flex tw-space-x-2">
                    <div>
                        <DatePicker
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            @on-change="onChangeYear"
                            :value="year"
                        ></DatePicker>
                    </div>
                    <div>
                        <DatePicker
                            type="month"
                            format="MM"
                            placeholder="Select Month"
                            @on-change="onChangeMonth"
                            :value="month"
                        >
                        </DatePicker>
                    </div>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            v-model="search"
                            search
                            :placeholder="$t('monthlyCompensate.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                size="small"
                max-height="700"
                :loading="loading"
                :data="resources"
                stripe
            >
                <template slot-scope="{ row }" slot="employeeId">
                    {{ $root.$i18n.locale === 'kh' ? row.employee_name_kh : row.employee_name_en }}
                </template>
                <template slot-scope="{ row }" slot="compensateId">
                    {{ row.compensate_item }}
                </template>
                <template slot-scope="{ row }" slot="compensate">
                    <span class="tw-font-bold text-success">{{
                        formatNumber(row.compensate)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="description">
                    {{ row.description }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                        placement="left"
                        :transfer="true"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="
                        fetchData({
                            filter: {
                                search: search,
                                cycle_year: year,
                                cycle_month: month
                            }
                        })
                    "
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showSalaryBackPay"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyCompensate.importSalaryBackPay')"
            >
                <salary-back-pay ref="salary_back_pay" />
            </Modal>

            <Modal
                v-model="showAnnualLeaveRemaining"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyCompensate.importAnnualLeaveRemaining')"
            >
                <annual-leave-remaining ref="annual_leave_remaining" />
            </Modal>
            <Modal
                v-model="showAllowanceBackPay"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1052"
                width="1200px"
                :title="$t('monthlyCompensate.importAllowanceBackPay')"
            >
                <allowance-back-pay ref="allowance_back_pay" />
            </Modal>
        </ts-panel>
        <!-- v-if="showForm"  -->
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('monthlyCompensate.monthlyCompensate')"
        >
            <FormComponent
                ref="form_action"
                @cancel="clearEdit"
                @fetchData="
                    fetchData({
                        filter: {
                            search: search,
                            cycle_year: year,
                            cycle_month: month
                        }
                    })
                "
            />
        </Modal>
    </div>
</template>

<script>
import FormComponent from './form'
import SalaryBackPay from './salary-back-pay.vue'
import AnnualLeaveRemaining from './annual-leave-remaining.vue'
import AllowanceBackPay from './allowance-back-pay.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
export default {
    name: 'monthly-compensate',
    components: {
        FormComponent,
        SalaryBackPay,
        AnnualLeaveRemaining,
        AllowanceBackPay
    },
    data () {
        return {
            loading: false,
            showSalaryBackPay: false,
            showAnnualLeaveRemaining: false,
            showAllowanceBackPay: false,
            width: 'width: 300px',
            showForm: false,
            year: moment().format('YYYY'),
            month: moment().format('MM'),
            model: {
                compensate_id: null
            },
            itemCompensate: []
        }
    },
    computed: {
        ...mapState('payroll/monthlyCompensate', ['resources', 'pagination']),
        ...mapGetters(['formatNumber']),
        search: {
            get () {
                return this.$store.state.payroll.monthlyCompensate.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/monthlyCompensate/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'payroll/monthlyCompensate/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('monthlyCompensate.cardId'),
                    key: 'card_id',
                    sortable: true
                },
                {
                    title: this.$t('monthlyCompensate.employeeId'),
                    slot: 'employeeId',
                    sortable: true
                },
                {
                    title: this.$t('monthlyCompensate.compensateId'),
                    key: 'compensate_item',
                    slot: 'compensateId',
                    sortable: true
                },
                {
                    title: this.$t('monthlyCompensate.cycleMonthAndYear'),
                    key: 'cycle_month_and_year',
                    align: 'center',
                    sortable: true,
                    width: '200'
                },
                {
                    title: this.$t('monthlyCompensate.compensate'),
                    slot: 'compensate',
                    align: 'right',
                    sortable: true
                },
                {
                    title: this.$t('monthlyCompensate.description'),
                    slot: 'description',
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('payroll/monthlyCompensate', ['formViewModel']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        addNew () {
            this.$refs.form_action.clearInput()
            this.$refs.form_action.fetchResource({
                fnName: 'itemCompensate,employeeProfile'
            })
            this.showForm = true
        },
        onImportCompensate (compensate) {
            if (compensate.custom_keyword == 'ANL') {
                this.showAnnualLeaveRemaining = true
                this.$refs.annual_leave_remaining.model.compensate_id =
                compensate.compensate_id
                this.$refs.annual_leave_remaining.model.custom_keyword =
                compensate.custom_keyword
                this.$refs.annual_leave_remaining.fetchResource()
            }
            if (compensate.custom_keyword == 'SBP') {
                this.showSalaryBackPay = true
                this.$refs.salary_back_pay.model.compensate_id =
                compensate.compensate_id
                this.$refs.salary_back_pay.model.custom_keyword =
                compensate.custom_keyword
                this.$refs.salary_back_pay.fetchResource()
            }
            if (compensate.custom_keyword == 'ABP') {
                this.showAllowanceBackPay = true
                this.$refs.allowance_back_pay.model.compensate_id =
                compensate.compensate_id
                this.$refs.allowance_back_pay.model.custom_keyword =
                compensate.custom_keyword
                this.$refs.allowance_back_pay.fetchResource()
            }
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/monthlyCompensate/fetch', attributes)
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (row) {
            this.showForm = true
            this.$store.commit('payroll/monthlyCompensate/SET_EDIT_DATA', row)
            this.$refs.form_action.fetchResource({
                fnName: 'itemCompensate,employeeProfile'
            })
        },
        async onDelete (row) {
            row._deleting = true
            this.$store
                .dispatch('payroll/monthlyCompensate/destroy', row.record_id)
                .then(response => {
                    this.fetchData({
                        filter: {
                            search: this.search,
                            cycle_year: this.year,
                            cycle_month: this.month
                        }
                    })
                    row._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    row._deleting = false
                })
        },
        onChangeYear (year) {
            this.year = year
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_year: year,
                    cycle_month: this.month
                }
            })
        },
        onChangeMonth (month) {
            this.month = month
            this.fetchData({
                filter: {
                    search: this.search,
                    cycle_month: month,
                    cycle_year: this.year
                }
            })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY COMPENSATE',
                desc: not.text
            })
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit('payroll/monthlyCompensate/SET_EDIT_DATA', {})
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({
                filter: {
                    search: search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource({
                fnName: 'itemCompensate'
            })
            vm.fetchData({
                filter: {
                    search: vm.search,
                    cycle_year: vm.year,
                    cycle_month: vm.month
                }
            })
        })
    }
}
</script>

<style lang="scss" scoped></style>
