<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12 mb-3">
                    <label class="col-label form-label required tw-text-xs">
                        {{ $t("monthlyCompensate.employeeId") }}
                    </label>
                    <Select
                        v-model="model.employee_id"
                        filterable
                        remote
                        :remote-method="searchEmployee"
                        :loading="loading"
                        class="from-label"
                        :class="{
                            'is-invalid': errors.has('employee_id')
                        }"
                    >
                        <Option
                            v-for="(emp, index) in employeeProfile"
                            :value="emp.employee_id"
                            :key="index"
                        >
                            {{
                                emp.card_id +
                                    "-" +
                                    emp.employee_name_kh +
                                    "-" +
                                    emp.employee_name_en
                            }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_id')"
                    >
                        {{ errors.first("employee_id") }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label class="col-label form-label required tw-text-xs">
                            {{ $t("monthlyCompensate.compensateId") }}
                        </label>
                        <Select
                            v-model="model.compensate_id"
                            filterable
                            remote
                            :remote-method="searchCompensate"
                            :loading="loading"
                            class="from-label"
                            :class="{
                                'is-invalid': errors.has('compensate_id')
                            }"
                        >
                            <Option
                                v-for="(cps, index) in itemCompensate"
                                :value="cps.compensate_id"
                                :key="index"
                            >
                                {{ cps.compensate_item }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('compensate_id')"
                        >
                            {{ errors.first("compensate_id") }}
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="col-label form-label required tw-text-xs">
                            {{ $t("monthlyCompensate.compensate") }}
                        </label>
                        <Poptip trigger="focus">
                            <Input
                                v-model.number="model.compensate"
                                type="number"
                                :placeholder="
                                    $t('monthlyAllowance.enterNumber')
                                "
                                style="width: 131%"
                                ref="inc_bonus"
                                :class="{
                                    'is-invalid': errors.has('compensate')
                                }"
                            >
                            </Input>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('compensate')"
                            >
                                {{ errors.first('compensate') }}
                            </div>
                            <div slot="content">
                                {{
                                    model.allowance
                                        ? formatNumber(model.allowance)
                                        : $t('monthlyAllowance.enterNumber')
                                }}
                            </div>
                        </Poptip>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('compensate')"
                        >
                            {{ errors.first("compensate") }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label
                            class="col-label form-label required tw-text-xs"
                            >{{ $t("monthlyCompensate.cycleYear") }}</label
                        >
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width:233px"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first("cycle_year") }}
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label
                            class="col-label form-label required tw-text-xs"
                            >{{ $t("monthlyCompensate.cycleMonth") }}</label
                        >
                        <div>
                            <date-Picker
                                :value="model.cycle_month"
                                type="month"
                                placeholder="Select Month"
                                style="width:233px"
                                format="MM"
                                @on-change="cycleMonthChange"
                                :class="{
                                    'is-invalid': errors.has('cycle_month')
                                }"
                            >
                            </date-Picker>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('cycle_month')"
                            >
                                {{ errors.first("cycle_month") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-label form-label tw-text-xs">
                        {{ $t("monthlyCompensate.description") }}
                    </label>
                    <div>
                        <textarea
                            v-model="model.description"
                            class="form-control"
                        >
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
        <div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}
                </ts-button>
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onUpdate"
                >
                    {{ $t("update") }}
                </ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions, mapGetters } from "vuex";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
export default {
    name: "monthly-compensate-form",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            model: {
                employee_id: null,
                compensate_id: null,
                compensate: null,
                cycle_year: moment().format('YYYY'),
                cycle_month: moment().format('MM'),
                description: null
            },
            itemCompensate: [],
            employeeProfile: []
        };
    },
    computed: {
        ...mapState("payroll/monthlyCompensate", ["edit_data"]),
        ...mapGetters(['formatNumber']),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("payroll/monthlyCompensate", ["formViewModel"]),
        async fetchResource(attributes) {
            this.loading = true;
            let response = await this.formViewModel({
                params: attributes
            });
            if (attributes.fnName) {
                attributes.fnName.split(",").forEach(fn => {
                    this[fn] = response[fn];
                });
            }
            this.setEditData();
            this.loading = false;
        },
        cycleYearChange(value) {
            this.model.cycle_year = value;
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value;
        },
        searchCompensate: debounce(async function(query) {
            var avoidSearch = this.itemCompensate.find(
                cps => cps.compensate_item == query.trim()
            );
            if (avoidSearch) return;
            this.fetchResource({
                fnName: "itemCompensate",
                cps_search: query
            });
        }, 500),
        searchEmployee: debounce(async function(query) {
            var avoidSearch = this.employeeProfile.find(
                emp =>
                    emp.card_id +
                        "-" +
                        emp.employee_name_kh +
                        "-" +
                        emp.employee_name_en ==
                    query.trim()
            );
            if (avoidSearch) return;
            this.fetchResource({
                fnName: "employeeProfile",
                emp_search: query
            });
        }, 500),
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("payroll/monthlyCompensate/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.$emit("cancel");
                        this.$emit("fetchData");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "payroll/monthlyCompensate/store",
                    Object.assign({}, this.model)
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.$emit("fetchData");
                        this.clearInput();
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("payroll/monthlyCompensate/update", {
                    id: this.edit_data.record_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.$emit("cancel");
                        this.$emit("fetchData");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY COMPENSATE",
                desc: not.text
            });
        },
        setEditData() {
            if (this.isUpdate) {
                this.model.employee_id = this.edit_data.employee_id;
                this.model.compensate_id = this.edit_data.compensate_id;
                this.model.compensate = this.edit_data.compensate;
                this.model.cycle_year = this.edit_data.cycle_year.toString();
                this.model.cycle_month = this.edit_data.cycle_month.toString();
                this.model.description = this.edit_data.description;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.employee_id = null;
            this.model.compensate_id = null;
            this.model.compensate = null;
            this.model.cycle_year = moment().format('YYYY');
            this.model.cycle_month = moment().format('MM');
            this.model.description = null;
        }
    }
};
</script>

<style lang="scss" scoped></style>
